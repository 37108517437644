<template>
  <div id="homePageCon">

    <HomePageTop class="HomePageTopBox"></HomePageTop>
    <!-- 轮播图 -->
    <SlideShow></SlideShow>
    <!-- 说明 -->
    <description />
    <!-- 推荐 特价商品-->
    <bargainGoods></bargainGoods>
    <!--  二级商品 -->
    <SecondaryProductRecommendation></SecondaryProductRecommendation>
    <!-- Rakumart帮助您从中国进口货物 -->
    <zhuanZhu />

    <Foot />
    <notificationDialog @clickIunderstand="dismissPrompt" ref="notificationDialogRef"> </notificationDialog>

  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop.vue"; //头部
import SlideShow from "../../components/homePage/SlideShow.vue"; //轮播图
import SecondaryProductRecommendation from "../../components/homePage/SecondaryProductRecommendation.vue"; //次级商品列表
import Foot from "../../components/foot/Foot.vue"; //脚部
import bargainGoods from "../../components/homePage/bargainGoods"; //特价商品
import zhuanZhu from "../../components/homePage/zhuanzhu";
import description from "../../components/homePage/description"; //说明
import notificationDialog from '../../components/notificationDialog.vue'

let endTimestamp = 0
let startTimestamp = 0
export default {
  beforeRouteEnter(to, from, next) {
    // 不能访问组件实例 `this`
    // 当调用 next 后，组件实例才会被创建  

    const now = new Date();
    startTimestamp = now.getTime()
    if (window.location.origin == 'https://www.rakumart.us') {
      setTimeout(() => {
        __bl.sum('客户在首页停留15秒');
      }, 15000);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 访问组件实例 `this`  
    const leavedate = new Date();
    endTimestamp = leavedate.getTime()
    if (window.location.origin == 'https://www.rakumart.us') {
      __bl.avg('首页停留平均时长统计', (Math.abs(endTimestamp - startTimestamp)) / 1000);
    }
    next();
  },
  data() {
    return {
    };
  },
  components: {
    HomePageTop,
    SlideShow,
    SecondaryProductRecommendation,
    bargainGoods,
    Foot,
    zhuanZhu,
    description,
    notificationDialog

  },
  computed: {},
  created() {
    this.getcustomerNotice()

  },
  mounted() {


  },
  methods: {
    getcustomerNotice() {
      this.$api
        .getcustomerNotice({
          platform: 1,
        })
        .then((res) => {
          this.showDailyPrompt(res.data);


        })
        .catch((err) => {
          console.log(err);
        });
    },
    showDailyPrompt(data) {
      // 获取当前日期，只包含年月日部分  
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // 设置为当天开始的时间  
      const currentDateString = currentDate.toISOString().split('T')[0]; // 转换为ISO 8601格式的日期字符串  

      // 从localStorage中获取用户最后看到提示的日期  
      const lastPromptDate = localStorage.getItem('AmericaPClastPromptDate');
      // 从localStorage中获取用户当天是否已经点击了“知道了”  
      const dismissedToday = localStorage.getItem('AmericaPCdismissedToday');

      // 如果新的一天或者用户还没有点击“知道了”  
      if (!lastPromptDate || lastPromptDate !== currentDateString || dismissedToday !== 'true') {
        console.log(lastPromptDate, currentDateString);
        // 显示提示  


        this.$nextTick(() => {
          if (data) {
            // 示例用法：每天调用函数来显示或更新提示  
            // 假设这个函数在用户每次访问页面时都被调用  
            this.$refs.notificationDialogRef.open(data)
          }

        })
        // 在这里你可以添加代码来显示提示，并绑定dismissPrompt函数到“知道了”按钮上  

        // 更新localStorage中的日期为当前日期  
        localStorage.setItem('AmericaPClastPromptDate', currentDateString);

        // 如果今天是新的一天，并且用户之前点击过“知道了”，则重置dismissedToday  
        if (lastPromptDate !== currentDateString && dismissedToday === 'true') {
          localStorage.removeItem('AmericaPCdismissedToday'); // 重置“我知道了”的标志  
        }
      } else {
        // 如果是同一天且用户已经点击了“知道了”，则不显示提示  
        console.log('不显示提示。');
      }
    },
    // 在用户点击“知道了”的回调函数中，调用dismissPrompt函数  
    // dismissPrompt(); // 这个调用应该在实际的用户交互事件中，比如按钮点击事件中
    // 假设这里有一个用户点击“知道了”的回调函数  
    // 假设这里有一个用户点击“知道了”的回调函数  
    dismissPrompt() {
      // 当用户点击“知道了”时，设置localStorage中的dismissedToday为true  
      localStorage.setItem('AmericaPCdismissedToday', 'true');
    }

  },
};
</script>
<style lang="scss">
#couponDialog {
  border-radius: 6px;

  .el-dialog {
    border-radius: 6px;

    .el-icon-close {

      font-size: 20px;
      font-weight: 700;
    }
  }
}
</style>
<style scoped="scoped" lang="scss">
#homePageCon {
  background-color: #f6f6f6;
}
</style>
