<template>
  <div class="ourMain">
    <h1 class="ourMaintitle">{{ $fanyi('Rakumart帮助您从中国进口货物') }}</h1>
    <p class="jieshao">
      {{
        $fanyi(
          'Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。'
        )
      }}
    </p>
    <div class="ourBodyBox">
      <div class="Outopt" @click="$fun.toPage('/user-guide')">
        <div class="imgBox one">
          <div class="blockShodaw"></div>
          <div class="spanBox">
            {{ $fanyi('全面、便捷的中国进口管理。') }}
          </div>
        </div>
        <div class="neirongBox">
          <p class="neirongSpan">{{ $fanyi('以您为中心') }}</p>
          <p class="neironglianjie">
            {{ $fanyi('用户指南') }}
            <img src="../../assets/icon/jiantou.svg" alt="" />
          </p>
        </div>
      </div>
      <div class="Outopt" @click.stop="
        $fun.toPage('/international-freight')">
        <div class="imgBox two">
          <div class="blockShodaw"></div>
          <div class="spanBox">
            {{ $fanyi('我们为您购买产品并优化所有的物流成本') }}
          </div>
        </div>
        <div class="neirongBox">
          <p class="neirongSpan">{{ $fanyi('完整的物流服务') }}</p>
          <p class="neironglianjie">
            {{ $fanyi('后勤服务') }}
            <img src="../../assets/icon/jiantou.svg" alt="" />
          </p>
        </div>
      </div>
      <div class="Outopt" @click="$fun.toPage('/commission')">
        <div class="imgBox three">
          <div class="blockShodaw"></div>
          <div class="spanBox">
            {{ $fanyi('公平和有价值的佣金为您的业务') }}
          </div>
        </div>
        <div class="neirongBox">
          <p class="neirongSpan">{{ $fanyi('消费越多，等级越高，佣金越少') }}</p>
          <p class="neironglianjie">
            {{ $fanyi('佣金说明') }}
            <img src="../../assets/icon/jiantou.svg" alt="" />
          </p>
        </div>
      </div>
      <div class="Outopt" @click="$fun.toPage('/additional-services')">
        <div class="imgBox four">
          <div class="blockShodaw"></div>
          <div class="spanBox">
            {{ $fanyi('为所有的产品提供附加服务') }}
          </div>
        </div>
        <div class="neirongBox">
          <p class="neirongSpan">{{ $fanyi('其他服务和完整解决方案') }}</p>
          <p class="neironglianjie">
            {{ $fanyi('附加服务') }}
            <img src="../../assets/icon/jiantou.svg" alt="" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  created() { },
  methods: {},
}
</script>
<style type="text/css">
.st0 {
  fill: #1a289d;
}

.st1 {
  fill: #ffffff;
}
</style>
<style lang="scss" scoped="scoped">
@import '../../css/mixin.scss';

.ourMain {
  border-top: solid 1px #dbdbdb;
  padding: 35px 0 0 0;
  margin: 105px auto 0;
  width: 1400px;

  .ourMaintitle {
    font-size: 21px;
    line-height: 21px;
    font-weight: 600px;
    height: 32px;
  }

  .jieshao {
    margin-bottom: 35px;
    font-size: 15px;
    font-weight: 400;
    width: 744.9px;
    height: 45px;
    line-height: 1.8em;
  }

  .ourBodyBox {
    display: flex;
    margin-bottom: 70px;

    .Outopt {
      width: 330px;
      margin: 0 10px;
      border-radius: 6px;
      border: solid 1px #e4e4e4;
      height: 238.89px;
      overflow: hidden;
      background-color: white;
      cursor: pointer;
      user-select: none;

      .imgBox {
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        background-size: 100% 130%;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        .blockShodaw {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0;
          transition: 1.5s;
          z-index: 1;

          &:hover {
            opacity: 0.5;
          }
        }

        &.one {
          background-image: url('../../assets/img/picture1.png');
        }

        &.two {
          background-image: url('../../assets/img/picture2.png');
        }

        &.three {
          background-image: url('../../assets/img/picture3.png');
        }

        &.four {
          background-image: url('../../assets/img/picture4.png');
        }

        .spanBox {
          width: 258px;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 400;
          font-family: Roboto-Regular, Roboto;
          font-weight: 500;
          color: #fff;
        }
      }

      .neirongBox {
        padding-left: 30px;

        .neirongSpan {
          font-size: 15px;
          font-weight: 400;
          height: 24px;
        }

        .neironglianjie {
          font-size: 15px;
          font-weight: 600;
          height: 24px;

          img {
            width: 10px;
          }
        }
      }
    }
  }
}
</style>
