<template>
  <!-- closePrompt -->
  <div class="page">

    <div class="con">
      <div class="goodsClass">
        <div class="allClass">

          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px"
            viewBox="0 0 544 545.3" xml:space="preserve" class="svg">
            <g>
              <path
                d="M218.3,245.9H58.6C43.9,245.9,32,234,32,219.3V59.7C32,45,43.9,33.1,58.6,33.1h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,234,232.9,245.9,218.3,245.9z M58.6,59.7v159.6h159.6V59.7L58.6,59.7z M58.6,46.4l0,13.3h0V46.4z">
              </path>
            </g>
            <g>
              <path
                d="M536.2,158.3L423.3,271.2c-10.4,10.4-27.3,10.4-37.6,0L272.8,158.3c-10.4-10.4-10.4-27.2,0-37.6L385.7,7.8  c10.4-10.4,27.3-10.4,37.6,0l112.9,112.9C546.6,131,546.6,147.9,536.2,158.3L536.2,158.3z M291.6,139.5l112.9,112.9l112.9-112.9  L404.5,26.6L291.6,139.5z M282.2,130.1l9.4,9.4l0,0L282.2,130.1z">
              </path>
            </g>
            <g>
              <path
                d="M484.3,512H324.7c-14.7,0-26.6-11.9-26.6-26.6V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C510.9,500.1,499,512,484.3,512z M324.7,325.7v159.6h159.6V325.7L324.7,325.7z M324.7,312.4l0,13.3h0V312.4z">
              </path>
            </g>
            <g>
              <path
                d="M218.3,512H58.6C43.9,512,32,500.1,32,485.4V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,500.1,232.9,512,218.3,512z M58.6,325.7v159.6h159.6V325.7L58.6,325.7z M58.6,312.4l0,13.3h0V312.4z">
              </path>
            </g>
          </svg>
          <span>{{ $fanyi("特色类别") }}</span>
          <!-- 1688 -->
          <a href="https://sale.1688.com/" target="_blank"><img class="pic" src="../../assets/Alibaba.svg" alt=""></a>

        </div>
        <div class="classMainBox">
          <div class="goodsClassMain" v-for="(classItem, classIndex) in lists" :key="'class' + classIndex">
            <div class="goodsCl" @click="toPage(classItem)">
              <div class="classImg">
                <img :src="classItem.default_icon" alt="" />
                <img :src="classItem.trigger_icon" alt="" />
              </div>
              <div class="classSpan">
                <span>{{
                  !$store.state.showChinese
                    ? classItem.translation_name
                    : classItem.chinese_name
                }}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" height="512" viewBox="0 0 24 24" width="512">
                  <g id="_17" data-name="17">
                    <path
                      d="m15 19a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1 -.7 1.71z">
                    </path>
                  </g>
                </svg>
              </div>
            </div>

            <div class="classChildBox">
              <div class="classChild">
                <div>
                  <div class="className">
                    {{
                      !$store.state.showChinese
                        ? classItem.translation_name
                        : classItem.chinese_name
                    }}
                  </div>
                  <a v-for="(childsItem, childsIndex) in classItem.childs" :key="childsIndex" href="#"
                    @click="toPage(childsItem)">{{
                      !$store.state.showChinese
                        ? childsItem.translation_name
                        : childsItem.chinese_name
                    }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner">
        <el-carousel trigger="click" height="540px" :interval="interval" arrow="never">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index" v-show="item.status">
            <!-- 假如图片有指定连接才可以点击跳转 -->
            <a :href="item.href_url" v-if="item.href_url">
              <img :src="item.img_url" alt="" class="bannerImg" />
            </a>
            <img :src="item.img_url" alt="" class="bannerImg" v-else />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerList: [],
      interval: 5000,
      lists: [],
      showMore: false, //是否显示11个分类以外的分类

    };
  },
  components: {},
  computed: {

  },
  created() {
    this.getShopBanner();
    this.getData();
  },

  methods: {
    // 显示更多分类
    showClassMore() {
      this.showMore = !this.showMore;
    },
    loginOut() {
      localStorage.removeItem("user_token");
      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.toPage("/");
      }
    },
    getShopBanner() {
      this.$api.getShopBanner().then((res) => {
        this.interval = res.data.switch_time * 1000;
        this.bannerList = res.data.list;
      });
    },
    // 获取搜索框分类数据和首页分类数据
    getData() {
      this.$api
        .getShopGroup(this.form)
        .then((result) => {
          this.changeData(result.data);
          this.lists = result.data;
          this.$store.commit("getgoodsList", result.data);
        })
        .catch((err) => { });
    },
    toPage(classItem) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('用户通过分类搜索数量统计数量');
      }
      this.$fun.toPage(
        '/commoditySearch?keyword=' +
        classItem.translation_name +
        '&CN=' +
        this.$fun.toCode(classItem.chinese_name) + "&type=classificationSearch"
      )

    },
    // 子分类排序
    changeData(data) {

      data.forEach((data1Item) => {
        data1Item.childs.sort(duiBi);
      });
      function duiBi(a, b) {
        // time 是时间的字段 a-b 正序 b-a 倒序
        return Date.parse(b.sort) - Date.parse(a.sort);
      }


    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin";

.paddingtop {
  padding-top: 48px !important;
}

.page {
  margin-bottom: 30px;

  .con {
    margin: 0 auto;
    width: $pageWidth;
    display: flex;

    .goodsClass {
      width: 300px;
      margin-right: 20px;
      position: relative;

      .allClass {
        border-radius: 6px 6px 0 0;
        height: 49.58px;
        background-color: #ff730b;
        padding: 0px 13px 1px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .svg {
          width: 20px;
          margin-right: 15px;
          fill: white !important;
          color: white;
        }

        .pic {
          width: 40px;
          height: 23px;
          margin-left: 35px;
        }

        span {
          color: white;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .classMainBox {
        background-color: white;
        padding: 5px 0px 5px 0px;
        min-height: 490px;
        border: #e8e8e8 solid 1px;
        border-radius: 0 0 6px 6px;
        display: flex;
        flex-direction: column;

        .goodsClassMain {
          height: 40px;
          display: flex;
          cursor: pointer;
          background-color: white;

          &:hover {
            .goodsCl {
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
              position: relative;
              z-index: 2;
              background-color: white;
              position: relative;

              &:before {
                content: "";
                background-color: white;
                position: absolute;
                right: -15px;
                width: 20px;
                height: 100%;
                z-index: 115;
                opacity: 1;
                top: 0;
              }
            }

            .classChild {
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
              border: 1px solid #e8e8e8;
              border-radius: 6px;
              position: relative;
              // border: solid;
              left: 2px;
              z-index: 9;
            }
          }

          .goodsCl {
            width: 100%;
            display: flex;
            padding-left: 20px;
            padding-right: 25px;

            .classImg {
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                color: white;
              }

              svg {
                width: 22px;
                height: 22px;
                margin-right: 15px;
              }

              img {
                width: 22px;
                height: 22px;
                margin-right: 15px;

                &:last-child {
                  display: none;
                }
              }
            }

            .classSpan {
              display: flex;
              align-items: center;
              width: 100%;

              span {
                font-size: 14px;
                font-weight: 500;
              }

              svg {
                transform: rotate(180deg);
                width: 14px;
                height: 26px;
                margin-left: auto;
              }
            }
          }

          .classChildBox {
            position: absolute;
            left: 100%;
            width: 255px;
            display: none;
            z-index: 3;
            top: 50px;
            overflow: hidden;
            height: 100%;
            padding-bottom: 50px;

            .shadowBlock {
              // border: solid;
              height: 100%;
            }

            .classChild {
              >div {
                max-height: 100%;
              }

              padding: 0 0px 20px 20px;
              margin-left: -20px;
              width: 235px;
              height: 100%;
              overflow-y: auto;

              &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 6px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
              }

              &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #c0c0c0;
              }

              &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
              }

              display: flex;
              flex-wrap: wrap;
              background-color: white;

              .className {
                font-weight: 600;
                font-size: 14px;
                position: sticky;
                top: 0px;
                padding-top: 20px;
                padding-left: 30px;
                padding-bottom: 10px;
                color: #ff730b;
                background-color: white;
                width: 100%;
              }

              a {
                width: 210px;
                height: 34px;
                border-radius: 5px;
                text-overflow: -o-ellipsis-lastline;
                line-height: 14px;
                overflow: hidden;
                display: flex;
                padding: 2px 0px;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                padding-left: 30px;
                transition: 0.3s;

                &:hover {
                  background-color: #f8f9fd;
                }
              }
            }
          }

          &:hover {
            .classImg {
              img {
                width: 22px;
                height: 22px;
                margin-right: 15px;
                display: none;

                &:last-child {
                  display: block;
                }
              }
            }

            .classSpan {
              span {
                color: #ff730b;
              }
            }

            .classChildBox {
              display: block;
            }
          }
        }
      }
    }

    .banner {
      flex: 1;

      .bannerImg {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }

      // margin: 20px 30px 0 20px;
      // 轮播图两侧样式
      /deep/.el-carousel__arrow--right,
      /deep/.el-carousel__arrow--left {
        width: 41px;
        height: 41px;
        background: #90909f !important;
        opacity: 0.75;
        border-radius: 50%;

        &:hover {
          opacity: 1;
        }
      }

      /deep/ .el-carousel__arrow {
        background: rgba(255, 255, 255, 0.5);
        text-align: center;

        i {
          color: white;
          font-size: 23px;
          font-weight: 700;
        }
      }

      /deep/.el-carousel__button {
        width: 12px;
        height: 12px;
        background: #ffffff;
        border-radius: 50%;
      }

      /deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active .el-carousel__button {
        outline: 3px solid $homePageSubjectColor;
        background-color: transparent;
      }

      /deep/.el-carousel__indicator--horizontal {
        padding: 12px 5px;
      }

      /deep/.el-carousel__indicators--horizontal {
        position: absolute;
        height: 42px;
        right: 20px !important;
        text-align: right;
        transform: translateX(0%);

        .is-active {
          .el-carousel__button {
            background-color: #ff730b !important;
            outline: 0 !important;
            height: 12px !important;
            width: 12px !important;
          }
        }
      }
    }
  }
}
</style>
