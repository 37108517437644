var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"classifyGoods"},_vm._l((_vm.goodsList),function(item,index){return _c('div',{key:index,staticClass:"classify"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"title",style:({ background: item.default_back_color })},[_c('h3',[_vm._v(_vm._s(item.translation_name))])]),_c('div',{staticClass:"menus"},_vm._l((item.childs),function(menu,menus){return _c('div',{key:menus,staticClass:"menu",class:{ checked: item.index == menus },on:{"mouseover":function($event){return _vm.getResources(menu, index, menus)},"click":function($event){_vm.$fun.toPage(
              '/commoditySearch?keyword=' +
              menu.translation_name +
              '&CN=' +
              _vm.$fun.toCode(menu.chinese_name)
            )}}},[_c('div',{staticClass:"menus-name",style:({
            background:
              item.index == menus ? item.trigger_back_color : 'transparent',
            color: item.index == menus ? item.default_back_color : '',
          })},[_vm._v(" "+_vm._s(menu.translation_name)+" ")]),_c('div',{staticClass:"border",style:({
            background:
              item.index == menus ? item.default_back_color : 'transparent',
          })})])}),0)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg"},[_c('el-image',{attrs:{"src":item.web_img}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1),_c('div',{staticClass:"goodsList"},_vm._l((item.resources),function(goods,goodsIndex){return _c('div',{key:goodsIndex,staticClass:"item",on:{"click":function($event){return _vm.$fun.toCommodityDetails({
            goods_id: goods.goods_id,
            shop_type: goods.shop_type,
          })}}},[_c('div',{staticClass:"img"},[_c('el-image',{attrs:{"src":goods.imgUrl}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1),_c('div',{staticClass:"title",attrs:{"title":goods.title}},[_vm._v(_vm._s(goods.title))]),_c('div',{staticClass:"price"},[_vm._v(" $ "+_vm._s(_vm.$fun.EURNumSegmentation( _vm.$fun.ceil(goods.goodsPrice * _vm.exchangeRate) ))+" "),_c('span',{staticStyle:{"font-size":"16px","font-weight":"500"}},[_vm._v("￥"+_vm._s(_vm.$fun.RMBNumSegmentation(goods.goodsPrice)))])])])}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }