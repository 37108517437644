<template>
  <div id="loadingpop">
    <el-dialog class="loadingpc" :title="title" :visible.sync="dialogVisiblePC" center width="800">
      <div class="content" v-html="data.content">

      </div>
      <span slot="footer" class="dialog-footer">


        <el-button type="primary" @click="Iunderstand">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog title="提示" class="loadingh5" :visible.sync="dialogVisible" width="295">
      <div class="content" v-html="data.content">

      </div>
      <span slot="footer" class="dialog-footer">


        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisiblePC: false, // PC
      dialogVisible: false, // H5
      title: '标题',
      data: {},
    }
  },
  methods: {

    open(row) {

      this.data = row;
      this.title = row.title
      this.dialogVisiblePC = true
    },
    Iunderstand() {
      this.dialogVisiblePC = false
      this.$emit('clickIunderstand')
    }

  },
}
</script>

<style lang="scss">
#loadingpop {

  display: flex;
  align-items: center;

  .loadingpc {
    display: flex;
    align-items: center;


    .el-dialog {
      max-height: 646px;
      background: #FFFFFF;

      border-radius: 10px !important;
      padding: 0px !important;

      .el-dialog__header {
        height: 68px;

        font-size: 18px;
        color: #222222;
        padding: 0 21px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;

        .el-dialog__close {
          font-weight: 500;
          font-size: 25px;
          color: #333;
        }
      }

      .el-dialog__footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-button {
          width: 120px;
          height: 40px;
          background: #FF730B;
          border-radius: 4px;
          border: none;
          margin-bottom: 10px;
        }
      }

      .el-dialog__body {
        padding: 40px;

        .content {
          overflow-y: scroll;
          max-height: 600px;

          * {
            font-weight: 400;
            font-size: 16px;
            color: #222222;
            line-height: 20px;
          }

          img {
            width: 218px;
            height: 145px;
          }
        }
      }


    }



  }

  .loadingh5 {
    display: flex;
    align-items: center;


    .el-dialog {

      background: #FFFFFF;
      padding: 0px !important;

      border-radius: 10px !important;
      width: 295px !important;

      .el-dialog__header {
        height: 50px;

        font-size: 18px;
        color: #222222;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        border-bottom: 1px solid #DBDBDB;

        .el-dialog__close {
          font-weight: 500;
          font-size: 25px;
          color: #333;
          margin-top: -10px;
        }
      }

      .el-dialog__footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-button {
          width: 120px;
          height: 40px;
          background: #FF730B;
          border-radius: 6px;
          border: none;
          margin-bottom: 10px;
        }
      }

      .el-dialog__body {
        padding: 40px;

        .content {
          overflow-y: scroll;
          max-height: 440px;


          * {
            font-weight: 400;
            font-size: 16px;
            color: #222222;
            line-height: 20px;
          }

          img {
            width: 120px;
            height: 100px;
          }
        }
      }


    }



  }
}
</style>