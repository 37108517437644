<template>
  <div class="description">
    <div class="list">
      <div class="item">
        <div class="template">
          <div class="img">
            <img src="../../assets/hometop/icon1.svg" alt="" />
          </div>
          <div class="p">
            {{ $fanyi('成为rakumart的一员') }}
            <router-link to="/register"> {{ $fanyi('现在注册') }}</router-link>
            <i class="el-icon-arrow-right" style="margin-left: 8px;"></i>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="template">
          <div class="img">
            <img src="../../assets/hometop/icon2.svg" alt="" />
          </div>
          <div class="p">
            {{ $fanyi('搜索并下单您感兴趣的产品') }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="template">
          <div class="img">
            <img src="../../assets/hometop/icon3.svg" alt="" />
          </div>
          <div class="p">
            {{ $fanyi('中国仓库将存储并为您检查货物') }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="template">
          <div class="img">
            <img src="../../assets/hometop/icon4.svg" alt="" />
          </div>
          <div class="p">
            {{ $fanyi('您需要支付国际运输的费用') }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="template">
          <div class="img">
            <img src="../../assets/hometop/icon5.svg" alt="" />
          </div>
          <div class="p">
            {{ $fanyi('您可以查询订单进程，最后货物送达到你的手中') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.description {
  width: 1400px;
  // background-color: pink;
  margin: 0 auto 40px;

  .list {
    display: flex;

    .item {
      flex: 1;
      height: 40px;
      // background: #ffffff;
      // border-radius: 6px;
      // border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      &:nth-last-child(1) {
        margin: 0;
      }

      .template {
        display: flex;
      }

      .img {
        width: 24px;
        height: 24px;
        background: #ffffff;
        border: 2px solid #ff730b;
        border-radius: 50%;
        margin: 0 auto 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
        }

        margin-right: 5px;
        margin-top: 2px;
      }

      .p {
        color: #222222;
        line-height: 24px;
        // text-align: center;

        font-size: 15px;

        a {
          color: #222222;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
