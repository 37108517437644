<template>
  <div class="recommendGoodsMain">
    <nav>
      <h4>{{ $fanyi("推荐商品") }}</h4>
      <div class="iconBox">
        <img src="../../assets/icon/flecha-izquierda.svg" alt="" @click="shopCarouselChange('left')" />
        <img src="../../assets/icon/flecha-izquierda-1.svg" alt="" @click="shopCarouselChange('right')" />
      </div>
    </nav>
    <div v-if="goodsList.length == 0">
      <el-carousel trigger="click" ref="shopCarousel" :interval="3000" :autoplay="autoplay"
        @mouseover.native="autoplay = false" @mouseleave.native="autoplay = true">
        <el-carousel-item>
          <div class="recommendGoodsBox">
            <div class="recommendGoods" v-for="i in 6" :key="i">
              <div class="imageBox">
                <div class="notHaveData">
                  <i class="el-icon-loading"></i>
                </div>
              </div>
              <div style="margin-top: 20px">
                <el-skeleton animated> </el-skeleton>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else>
      <el-carousel trigger="click" ref="shopCarousel" :interval="3000" :autoplay="autoplay"
        @mouseover.native="autoplay = false" @mouseleave.native="autoplay = true">
        <el-carousel-item v-for="(item, index) in goodsList" :key="index" v-show="item.status">
          <div class="recommendGoodsBox">
            <div v-for="(goodsItem, goodsIndex) in item" :key="goodsIndex" class="recommendGoods"
              @click="toCommodityDetails(goodsItem)">
              <div class="imageBox">
                <el-image :src="goodsItem.imgUrl">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </div>
              <div class="goodsTitle" :title="goodsItem.title">
                {{ goodsItem.title }}
              </div>
              <div class="priceBox">
                <span class="priceBox">
                  <span>
                    $
                    {{
                      $fun.EURNumSegmentation(
                        $fun.ceil(
                          goodsItem.goodsPrice * $store.state.exchangeRate
                        )
                      )
                    }}</span><span style="font-size: 16px; font-weight: 500">
                    ￥{{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div
      v-if="$route.name != 'cart'"
      class="lookMore"
      @click="
        $fun.toPage(
          '/commoditySearch?keyword=' +
            translation_name +
            '&CN=' +
            $fun.toCode(chinese_name)
        )
      "
    >
      <img
        style="width: 36px; height: 36px"
        src="../../assets/hometop/flechacategorias.svg"
        alt=""
      />
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      goodsList: [],
      autoplay: true,
      translation_name: "",
      chinese_name: "",
    };
  },
  components: {},
  computed: {},
  created() {
    this.getPersonalizedGoods();
  },
  methods: {
    // 获取推荐商品数据
    getPersonalizedGoods() {
      this.$api.getPersonalizedGoods().then((res) => {
        this.goodsList = res.data.result;
        this.chinese_name = res.data.chinese_name;
        this.translation_name = res.data.translation_name;
        this.ProcessingShopData();
      });
    },
    // 数据处理
    ProcessingShopData() {


      let lack = 6 - (this.goodsList ? this.goodsList.length % 6 : 0); //取得店铺商品数离6个的倍数差多少个商品的数量

      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList ? this.goodsList.push(this.goodsList[i]) : '';
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6);

      this.$forceUpdate();
    },
    // 滑动店铺商品列表
    shopCarouselChange(type) {
      if (type == "left") {
        this.$refs.shopCarousel.prev();
      } else if (type == "right") {
        this.$refs.shopCarousel.next();
      }
    },
    toCommodityDetails(goodsItem) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('点击推荐商品进入详情数量统计');
      }

      this.$fun.toCommodityDetails({
        goods_id: goodsItem.goods_id,
        shop_type: goodsItem.shop_type,
      })
    }
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../css/mixin.scss";

.recommendGoodsMain {
  width: $pageWidth;
  margin: 0 auto 0px;
  margin-bottom: 60px;

  nav {
    height: 55.88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h4 {
      color: black;
      font-size: 21px;
      font-weight: 600;
    }

    .iconBox {
      img {
        width: 40.88px;
        height: 40.88px;

        &:last-child {
          margin-left: 20px;
          margin-right: 30px;
        }

        opacity: 0.45;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  .recommendGoodsBox {
    display: flex;

    .recommendGoods {
      cursor: pointer;
      width: 213.31px;
      padding: 15px;
      height: 290px;
      background-color: white;
      border: #e8e8e8 solid 1px;
      border-radius: 6px;
      margin: 10px;
      transition: 0.3s;

      .imageBox {
        width: 100%;
        height: 187.31px;
        margin-bottom: 5px;
        text-align: center;
        background-color: white;

        /deep/.el-image {
          $imgSize: 181.31px;
          width: $imgSize;
          height: $imgSize;
          transition: 0.3s;
          text-align: center;

          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }
      }

      .goodsTitle {
        height: 39px;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
        color: #404040;
        font-family: "Roboto-Regular, Roboto";
        font-size: 14px;
        font-weight: 500;
      }

      .priceBox {
        color: #ff730b;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.6em;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 0px 15px rgb(223, 223, 223);
      }
    }
  }

  .lookMore {
    padding: 22px 34px;
    text-align: right;
    color: #1a289d;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    i {
      font-weight: 800;
      font-size: 14px;
    }
  }
}

/deep/.el-carousel__arrow--right,
/deep/.el-carousel__arrow--left {
  display: none;
  width: 100px;
  height: 100px;
  background: #ffffff;
  opacity: 0.6;
  border-radius: 50%;
}

/deep/ .el-carousel__arrow {
  background: rgba(255, 255, 255, 0.5);

  i {
    color: $homePageSubjectColor;
    font-size: 60px;
    font-weight: 700;
  }
}

/deep/.el-carousel__button {
  display: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
}

/deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  outline: 3px solid $homePageSubjectColor;
  background-color: transparent;
}

.notHaveData {
  $imgSize: 181.31px;
  width: $imgSize;
  height: $imgSize;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: $imgSize/4;
  }
}
</style>